<script>
import { v3ServiceApi } from "@/services/v3/v3.service";
import SettingsPermission from "@/permission/settings.permission";
import { XButton } from "@/components/ui-components/button";
import BaseLoading from "@/components/Reusable/BaseLoading.vue";
import { XIcon } from "@/components/ui-components/material-icons";
import { XCircularBackground } from "@/components/ui-components/circular-background";
import SettingsCreateDistrict from "@/views/settings/components/SettingsCreateDistrict.vue";

import BaseArrowLeftIcon from "@/components/icons/BaseArrowLeftIcon";
import BaseArrowRightIcon from "@/components/icons/BaseArrowRightIcon";

export default {
  name: "SettingsDistricts",
  components: {
    BaseArrowLeftIcon,
    BaseArrowRightIcon,

    BaseLoading,
    XButton,
    XIcon,
    XCircularBackground,
    SettingsCreateDistrict,
  },
  data() {
    return {
      currentLang: "",
      upsertType: "create",
      showCreateModal: false,
      showEditTagModal: false,
      editStorage: {},
      editTags: {},
      region: null,
      table: {
        items: [],

        loading: false,
      },
      permission: {
        view: SettingsPermission.getPermission("locations.view"),
        create: SettingsPermission.getPermission("locations.create"),
        edit: SettingsPermission.getPermission("locations.edit"),
        delete: SettingsPermission.getPermission("locations.delete"),
      },
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
    tableFields() {
      const fields = [
        {
          key: "id",
          label: this.$t("id"),
        },
        {
          key: "name",
          label: this.$t("title"),
          formatter: (v) => v[this.$i18n.locale],
        },

        // {
        //   key: "tags",
        //   label: "tags",
        //   thStyle: "width: 200px",
        // },
      ];
      if (this.permission.edit) {
        fields.push({
          key: "actions",
          label: "",
          thStyle: "width: 100px",
        });
      }
      return fields;
    },
  },
  watch: {
    query() {
      this.fetchItems();
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    startLoading() {
      this.table.loading = true;
    },
    finishLoading() {
      this.table.loading = false;
    },
    createVersion() {
      this.setUpsertType("create");
      this.openVersionCreationModal();
    },
    async fetchItems() {
      try {
        this.startLoading();
        const response = await v3ServiceApi.regions.show({
          id: this.$route.params.id,
        });
        this.region = response.data.result;
      } catch (e) {
        this.toastedWithErrorCode(e);
      } finally {
        this.finishLoading();
      }
    },
    setUpsertType(eType) {
      if (["create", "edit"].includes(eType)) {
        this.upsertType = eType;
      }
    },
    backNavigation() {
      this.$router.back();
    },
    openVersionCreationModal() {
      this.showCreateModal = true;
    },
    closeVersionCreationModal() {
      this.showCreateModal = false;
    },

    translationCreated() {
      this.closeVersionCreationModal();
      this.fetchItems();
    },

    async deleteItem(typeId) {
      this.$swal({
        title: this.$t("sweetAlert.title"),
        text: this.$t("sweetAlert.text"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("sweetAlert.yes"),
      }).then(async (result) => {
        if (result.value) {
          try {
            this.startLoading();
            await v3ServiceApi.districts.remove({ id: typeId });
            await this.fetchItems();
          } catch (e) {
            this.toastedWithErrorCode(e);
          } finally {
            this.finishLoading();
          }
        }
      });
    },

    async editVersion(item) {
      try {
        this.editStorage = item;
        this.setUpsertType("edit");
        this.openVersionCreationModal();
      } catch (e) {
        this.toastedWithErrorCode(e);
      }
    },
  },
};
</script>

<template>
  <div v-if="permission.view" class="app-settings-client-type">
    <!-- TODO: CLIENT TYPES TABLE   -->
    <div class="d-flex mb-4 justify-content-between">
      <div class="go__back" @click="backNavigation">
        <BaseArrowLeftIcon :width="32" :height="32" />
      </div>
      <h3
        class="x-font-size-1p5 font-craftworksans color-gray-400 d-flex align-items-center"
      >
        {{ region?.name?.[$i18n.locale] }}
      </h3>

      <x-button
        v-if="permission.create"
        variant="secondary"
        :text="$t('addDistrict')"
        :bilingual="true"
        @click="createVersion"
      >
        <template #left-icon>
          <x-icon name="add" class="violet-600" />
        </template>
      </x-button>
    </div>

    <b-table
      id="my-table"
      ref="apartment-list-table"
      thead-tr-class="row__head__bottom-border"
      tbody-tr-class="row__body__bottom-border"
      class="table__list mt-5"
      sticky-header
      borderless
      show-empty
      responsive
      sort-icon-left
      :items="region?.districts || []"
      :fields="tableFields"
      :busy="table.loading"
      :empty-text="$t('no_data')"
    >
      <template #table-busy>
        <base-loading />
      </template>

      <template #empty="scope">
        <span class="d-flex justify-content-center align-items-center">
          {{ scope["emptyText"] }}
        </span>
      </template>

      <template #cell(actions)="{ item }">
        <div class="float-right d-flex x-gap-1 cursor-pointer">
          <div :style="item.loading ? 'opacity: 0.5' : ''" title="save">
            <x-circular-background
              v-if="permission.delete"
              class="bg-red-600"
              @click="deleteItem(item.id)"
            >
              <x-icon name="delete" class="color-white" />
            </x-circular-background>
          </div>
          <div :style="item.loading ? 'opacity: 0.5' : ''" title="save">
            <x-circular-background
              v-if="permission.edit"
              class="bg-violet-600"
              @click="editVersion(item)"
            >
              <x-icon name="edit" class="color-white" />
            </x-circular-background>
          </div>
        </div>
      </template>
    </b-table>

    <settings-create-district
      :region="$route.params.id"
      v-if="showCreateModal"
      :upsert-type="upsertType"
      :edit-item="editStorage"
      @close-creating-modal="closeVersionCreationModal"
      @client-type-created="translationCreated"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/pagination";

@import "@/assets/scss/utils/b-table-redesign.scss";
@import "@/views/settings/assets/crudTable.scss";
.translation-tag {
  padding: 6px 12px;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.row-opacity {
  opacity: 0.2;
}
.tag:not(:first-child) {
  margin-top: 10px;
}
</style>
